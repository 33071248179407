import React from 'react'

import Layout from '../components/Layout/index'

const About = () => {
  return (
    <Layout>
      <div>About Page</div>
    </Layout>
  )
}

export default About
